import "@popperjs/core/dist/umd/popper.min";
import "./src/Global/custom.scss";
import "bootstrap/dist/js/bootstrap.min";
import GlobalContextProvider from "./src/components/Context/Context";
import { SearchProivder } from "./src/components/SearchFunction/SearchFunction";
import React from "react";

export const wrapRootElement = ({ element }) => (
  <GlobalContextProvider>
    <SearchProivder>
      {element}
    </SearchProivder>
  </GlobalContextProvider>
);

