exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-templates-admin-tsx": () => import("./../../../src/templates/Admin.tsx" /* webpackChunkName: "component---src-templates-admin-tsx" */),
  "component---src-templates-all-phones-tsx": () => import("./../../../src/templates/AllPhones.tsx" /* webpackChunkName: "component---src-templates-all-phones-tsx" */),
  "component---src-templates-articles-tsx": () => import("./../../../src/templates/Articles.tsx" /* webpackChunkName: "component---src-templates-articles-tsx" */),
  "component---src-templates-blog-tags-tsx": () => import("./../../../src/templates/BlogTags.tsx" /* webpackChunkName: "component---src-templates-blog-tags-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/Blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-phone-tsx": () => import("./../../../src/templates/Phone.tsx" /* webpackChunkName: "component---src-templates-phone-tsx" */),
  "component---src-templates-search-bar-tsx": () => import("./../../../src/templates/SearchBar.tsx" /* webpackChunkName: "component---src-templates-search-bar-tsx" */)
}

