import React, { useState } from "react";
export const GlobalStateContext = React.createContext<{
  searchQuery: string;
  setSearchQuery: any;
  yesModal: boolean;
  setYesModal: any;
  noModal: boolean,
  setNoModal: any;
  form: boolean;
  setForm: any;
  thankForm: boolean;
  setThankForm: any;
}>({
  searchQuery: "",
  setSearchQuery: (s) => null,
  yesModal: false,
  setYesModal: (val: any) => { },
  noModal: false,
  setNoModal: (val: any) => { },
  form: false,
  setForm: (val: any) => { },
  thankForm: false,
  setThankForm: (val: any) => { },
});

const GlobalContextProvider = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [yesModal, setYesModal] = React.useState(false);
  const [noModal, setNoModal] = React.useState(false);

  const [form, setForm] = React.useState(false);
  const [thankForm, setThankForm] = React.useState(false);

  const state = {
    searchQuery: searchQuery,
    setSearchQuery: setSearchQuery,
    yesModal: yesModal,
    setYesModal: setYesModal,
    noModal: noModal,
    setNoModal: setNoModal,
    form: form,
    setForm: setForm,
    thankForm: thankForm,
    setThankForm: setThankForm
  };

  return (
    <GlobalStateContext.Provider value={state}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export default GlobalContextProvider;
