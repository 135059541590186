import { useState, useContext, createContext } from "react";
import { GlobalStateContext } from "../Context/Context";
import React from "react";

const SearchContext = createContext({
  search: [],
});

export const useSearchFun = () => useContext(SearchContext);

export const SearchProivder: React.FC = (props) => {
  const a = useContext(GlobalStateContext);
  const searcher = a.searchQuery;
  const Searchset = a.setSearchQuery;

  const locInput = JSON.parse(localStorage.getItem('input'));
  const [search, setSearch] = useState(locInput);

  const clickSearch = (data, searcher) => {
    const tempPosts: any = [];
    const tempSearch: any = [];

    data.allStrapiPost.nodes.map((e) => {
      tempPosts.push(e.slug);
    });
    tempPosts.map((e) => {
      e.match(searcher) && tempSearch.push(e.match(searcher));
    });
    const input = tempSearch.map((val: any) => val['input'])
    const name = tempSearch.map((val: any) => val[0])
    localStorage.setItem('input', JSON.stringify(input));
    localStorage.setItem('name', JSON.stringify(name));
  };

  return (
    <SearchContext.Provider
      value={{ search, searcher, Searchset, clickSearch }}
    >
      {props.children}
    </SearchContext.Provider>
  );
};
