module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"8a47bbac4a9b91f371907541f491780a7b9ff4e9816ab504e5ee6ffdd25ed38680aeb0f2e3855aa94419661b63479de5729737a294d1e2d8f13421ff06f83f8fa7d9ae6b67f0fbf86c25f30933ed5407edec1f6f33a476e389f1c24f48bd013f276779ee21f66f655e01dc55f256336aa07c58d08c68d9e7adb112b577ee6324","devKey":"8a47bbac4a9b91f371907541f491780a7b9ff4e9816ab504e5ee6ffdd25ed38680aeb0f2e3855aa94419661b63479de5729737a294d1e2d8f13421ff06f83f8fa7d9ae6b67f0fbf86c25f30933ed5407edec1f6f33a476e389f1c24f48bd013f276779ee21f66f655e01dc55f256336aa07c58d08c68d9e7adb112b577ee6324","trackPage":false,"trackPageDelay":50,"host":"https://phonera.in/","delayLoad":true,"delayLoadTime":1000,"manualLoad":true,"customSnippet":"!function(){var analytics=window.analytics||[];...;analytics.load(\"${writeKey}\");analytics.page();}}();"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://phonera.in/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-243657871-1","G-CX6PFJBP1L"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"20cf48202cd6f2987a57c08f000cb182"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
